<script setup lang="ts">
import { scrollLock } from "@magnit/core/src/helpers/scrollLock";
import VShopSelect from "@magnit/unit-shop-select/src/VShopSelect.vue";
import type { IShopSelectShop } from "@magnit/unit-shop-select/src/shop-select.types";
import ShopListEmptyIcon from "@magnit/illustrations/src/assets/svg/illustrations/v1/280-character-nothing-found.svg";
import type { IStoreSearchStore } from "~/typings/api";

const storesStore = useStoresStore();
const { displayShopSelect } = storeToRefs(useAppStore());
const {
  mapBounds,
  shopSearchQuery,
  mapLoading,
  listLoading,
  storesListRef,
  yandexMapKey,
  selectedStoreLocation,

  handleMapBoundsChange,
  handleSearchQueryChange,
} = useShopSelect();
const modalOpen = ref(true);
const onClose = () => (displayShopSelect.value = false);
const handleShopSelectSubmit = (payload: { shop: IShopSelectShop; }) => {
  storesStore.setSelectedStore(payload.shop as IStoreSearchStore);
  storesStore.setStorePrompted("Y");
  onClose();
};

watch(displayShopSelect, (next) => {
  next ? scrollLock.enable() : scrollLock.disable();
});
</script>

<template>
  <Transition name="scale">
    <VShopSelect
      v-if="displayShopSelect"
      v-model:modal-open="modalOpen"
      class="v1-shop-select"
      :yandex-map-key="yandexMapKey"
      :map-loading="mapLoading"
      :shops-list-loading="listLoading"
      :bounding-box="mapBounds"
      :center="selectedStoreLocation"
      :shops="storesListRef"
      :shops-search-string="shopSearchQuery"
      :list-empty-component="ShopListEmptyIcon"
      @submit:bounding-box="handleMapBoundsChange"
      @submit:shops-search="handleSearchQueryChange"
      @submit:shop-select="handleShopSelectSubmit"
      @close:shop-select="onClose"
    />
  </Transition>
</template>

<style lang="postcss" scoped>
@keyframes scale-up {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.scale-enter-active {
  animation: scale-up 0.3s;
}

.scale-leave-active {
  animation: scale-up 0.3s reverse;
}
</style>
